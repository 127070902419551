<template>
  <div class="pt-3 pb-0 mb-3 display-6 fw-bold">{{ title }}</div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
